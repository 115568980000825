

body {
  background-image: url("./images/grey_wash_wall.png");
}

.image-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}


.navbar-custom {
  background: linear-gradient(#666967, #000000);
  -webkit-box-shadow: 5px 5px 34px #000000;
  -moz-box-shadow: 5px 5px 34px #000000;
  box-shadow: 5px  5px 34px #000000;
}



.card {
  color:black;
  background-color: rgba(255, 255, 255, 0.2);
}

.flex-row {
  color:black;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.img-a {
  vertical-align: bottom;
}


.img-b {
  max-width: 600px;
  max-height: 600px
}

.nav-item {
  color:white;
  padding-right:10px;
  padding-left:10px;
}

a:hover {
  text-decoration: none;
}

.carousel {
  padding: 20px;
} 

.BrainhubCarousel__arrows {
  background-color: #2f3330 !important;
}














